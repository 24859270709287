var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isModalOpen && _vm.formation)?_c('v-dialog',{attrs:{"max-width":"100%"},on:{"click:outside":_vm.close},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c('v-card',[_c('div',{staticClass:"modal"},[_c('div',[_c('ModalHeader',[_c('span',{staticClass:"modal__title"},[_vm._v(" Choose your "+_vm._s(_vm.title)+" squad! ")])]),_c('ModalBody',[_c('div',{staticClass:"modal__body"},[(_vm.isH2h)?_c('v-select',{staticClass:"modal__body__formation",attrs:{"full-width":false,"items":_vm.h2hFormations,"menu-props":{ bottom: true, offsetY: true },"attach":".modal__body__formation","chips":"","label":"Choose formation"},on:{"change":_vm.clearSquad},model:{value:(_vm.formation),callback:function ($$v) {_vm.formation=$$v},expression:"formation"}}):_vm._e(),_c('div',{staticClass:"modal__body__field"},[_c('div',{staticClass:"modal__body__field__st"},_vm._l((_vm.formation.st),function(count){return _c('div',{key:count},[_c('SquadChoiceTeammate',{attrs:{"dropdownItems":_vm.calculatedDroprownH2HItems.strikers,"player":_vm.squadSelection.striker[("st" + count)] ||
                      _vm.makeEmptyPlayer('Striker'),"uniqueId":("striker" + count),"position":"ST"},on:{"select-player":function($event){return _vm.playerChangeHandler($event, {
                        post: 'striker',
                        position: ("st" + count),
                        isSelect: true,
                      })},"deselect-player":function($event){return _vm.playerChangeHandler($event, {
                        post: 'striker',
                        position: ("st" + count),
                        isSelect: false,
                      })}}})],1)}),0),_c('div',{staticClass:"modal__body__field__mid"},_vm._l((_vm.formation.mid),function(count){return _c('div',{key:count},[_c('SquadChoiceTeammate',{attrs:{"dropdownItems":_vm.isCup
                        ? _vm.calculatedDroprownCupItems[("mid" + count)]
                        : _vm.calculatedDroprownH2HItems.midfielders,"player":_vm.squadSelection.midfielder[("mid" + count)] ||
                      _vm.makeEmptyPlayer('Midfielder'),"uniqueId":("midfielder" + count),"position":"MID"},on:{"select-player":function($event){return _vm.playerChangeHandler($event, {
                        post: 'midfielder',
                        position: ("mid" + count),
                        isSelect: true,
                      })},"deselect-player":function($event){return _vm.playerChangeHandler($event, {
                        post: 'midfielder',
                        position: ("mid" + count),
                        isSelect: false,
                      })}}})],1)}),0),_c('div',{staticClass:"modal__body__field__def"},_vm._l((_vm.formation.def),function(count){return _c('div',{key:count},[_c('SquadChoiceTeammate',{attrs:{"dropdownItems":_vm.isCup
                        ? _vm.calculatedDroprownCupItems[("def" + count)]
                        : _vm.calculatedDroprownH2HItems.defenders,"player":_vm.squadSelection.defender[("def" + count)] ||
                      _vm.makeEmptyPlayer('Defender'),"uniqueId":("defender" + count),"position":"DEF"},on:{"select-player":function($event){return _vm.playerChangeHandler($event, {
                        post: 'defender',
                        position: ("def" + count),
                        isSelect: true,
                      })},"deselect-player":function($event){return _vm.playerChangeHandler($event, {
                        post: 'defender',
                        position: ("def" + count),
                        isSelect: false,
                      })}}})],1)}),0),_c('div',{staticClass:"modal__body__field__gk"},[_c('SquadChoiceTeammate',{attrs:{"player":_vm.goalkeeper,"position":"GK"}})],1)])],1)]),_c('ModalFooter',[_c('div',{staticClass:"modal__buttons"},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"error","large":""},on:{"click":function($event){$event.preventDefault();return _vm.close.apply(null, arguments)}}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v(" fas fa-chevron-left")]),_vm._v(" Back ")],1),_c('v-btn',{staticClass:"white--text",attrs:{"disabled":!_vm.isSquadReady,"color":"#59A95D","large":""},on:{"click":function($event){$event.preventDefault();return _vm.submitSquadHandler.apply(null, arguments)}}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v(" fas fa-check")]),_vm._v(" Submit Squad ")],1)],1)])],1)])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }